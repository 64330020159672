body {
  overflow-y: scroll;
}

.app {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #333333;
  background-color: #E5E5E5;
  min-height: 100vh;
  overflow: hidden;
  line-height: 1.3;
}

/*.header {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  height: 53px;*/
/*  padding: 15px 24px;*/
/*  width: calc(100% - 48px);*/
/*  background-color: white;*/
/*  border-bottom: 1px solid #D4D4D4;*/
/*}*/

.flex {
  display: flex;
}

.text-button-container:hover {
  color: #000000 !important;
}

.text-link-container:hover {
  text-decoration: underline !important;
}

.round-button:hover {
  background-color: #F2F2F2 !important;
}

.primary-button-container:hover {
  background-color: #555555 !important;
}

.outline-button-container:hover {
  background-color: #F2F2F2 !important;
}

.outline-button-container-disabled:hover {
  background-color: #DDDDDD !important;
}

.search-input::placeholder {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #333333;
}

.search-input {
  /*border-radius: 6px;*/
  /*padding: 0 20px;*/
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  border: none;
  width: calc(100% - 96px);
  max-width: calc(100% - 96px);
  font-size: 16px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 112px;
  padding: 17px 48px;
}

.text-input {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  width: calc(100% - 24px);
  font-size: 14px;
  padding: 8px 12px;
}

.text-input::placeholder {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #B2B2B2;
}

.visible-on-hover:hover {
  opacity: 1 !important;
}

.menu-button:hover {
  background-color: #F2F2F2 !important;
}

.dropdown-item:hover {
  background-color: #F2F2F2 !important;
}

.dropdown-button-container:hover {
  color: #555555 !important;
}

.heart-button-container:hover {
  color: #555555 !important;
}

.hidden {
  display: none;
}

input[type="file"] {
  display: none;
}